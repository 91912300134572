<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid mb-12">
    <!-- Header -->
    <div v-if="agency" class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">
            {{ agency.name }}
          </h1>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <Loader :data="agency" />
    <!-- Body -->
    <div v-if="agency" class="post fs-6 d-flex flex-column-fluid">
      <div class="container-xxl">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-2 g-5 g-xl-9">
          <AgencyDetails :id="agency.id" />
          <AgencyContacts :id="agency.id" />
        </div>
        <AgencyPermits :id="agency.id" class="mt-9" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";
import Loader from "@/components/Loader";
import AgencyDetails from "@/components/agencies/AgencyDetails";
import AgencyContacts from "@/components/agencies/AgencyContacts";
import AgencyPermits from "@/components/agencies/AgencyPermits";

export default {
  name: "AgenciesInfos",
  components: {
    Loader,
    AgencyContacts,
    AgencyDetails,
    AgencyPermits,
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    ...mapGetters(["agency"]),
  },
  methods: {
    ...mapActions(["getAgency"]),
    showAgency(id) {
      this.getAgency(id);
    },
  },
  mounted() {
    const route = useRoute();
    this.id = route.params.id;
    this.showAgency(route.params.id);
  },
};
</script>
