<template>
  <div class="col-md-6">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6 bg-primary">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Contacts</h3>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="agencyContacts" />
      <!-- Card body -->
      <div v-if="agencyContacts" class="card-body pt-7 h-300px overflow-scroll">
        <div
          v-if="agencyContacts.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div v-for="contact in agencyContacts.results" :key="contact.id">
            <div class="mb-3">
              <router-link :to="'/contacts/' + contact.id" class="mb-0">
                {{ contact.last_name }} {{ contact.first_name }}
                <span
                  v-if="contact.relation_quality"
                  class="ms-2 badge bg-light-warning text-black"
                >
                  {{ contact.relation_quality }}
                  <i class="bi bi-star-fill text-warning h6"></i>
                </span>
              </router-link>
              <div>
                <span v-if="contact.position">
                  {{ contact.position }}
                </span>
                <span v-else class="text">Fonction non fournie </span>
              </div>
              <div>
                <span v-if="contact.agency">
                  {{ contact.agency.name }}
                </span>
                <span v-else class="text">Agence non fournie </span>
              </div>
              <div>
                <span v-if="contact.phone_number">
                  {{ contact.phone_number }}
                </span>
                <span v-else class="text">Numéro non fournie </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2 text-gray-700">
          Aucun contact associé à cette agence
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "AgencyContacts",
  components: {
    Loader,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["agencyContacts"]),
  },
  methods: {
    ...mapActions(["getAgencyContacts"]),
    async getAllAgencyContacts() {
      await this.getAgencyContacts(this.id);
    },
  },
  mounted() {
    this.getAllAgencyContacts();
  },
};
</script>
