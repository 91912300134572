<template>
  <!-- -->
  <div class="col-md-6">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header -->
      <div class="card-header px-6" style="background-color: #18ba70">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Infos agence</h3>
        </div>
        <div class="card-toolbar">
          <router-link :to="'/agencies/form/' + id" class="btn btn-color-white"
            >Modifier</router-link
          >
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="agency" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Nom de l'agence</div>
            <div v-if="agency.name" class="pt-2">{{ agency.name }}</div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Promoteur</div>
            <div v-if="agency.promoter" class="pt-2">
              <router-link
                :to="'/promoters/' + agency.promoter.id"
                class="text-gray-700"
              >
                {{ agency.promoter.name }}</router-link
              >
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Adresse de l'agence</div>
            <div v-if="agency.address" class="pt-2">
              {{ agency.address.number + " " }}
              {{ agency.address.street + " " }}
              {{ agency.address.zipcode + " " }} {{ agency.address.city }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Contact de l'agence</div>
            <div v-if="agency.phone_number" class="pt-2">
              {{ agency.phone_number }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import moment from "moment";

export default {
  name: "AgencyDetails",
  components: {
    Loader,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["agency"]),
  },
  methods: {
    ...mapActions(["getPermit"]),
    showAgency(id) {
      this.getAgency(id);
    },
  },
};
</script>
